@import '../../scss/base';

$places-input-height: 48px;

.places-autocomplete {
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;

  & > * + * {
    margin-left: $spacing-m;
  }

  &__box {
    position: relative;
    flex:1
  }

  &__icon {
    $icon-height: 16px;
    position: absolute;
    top: $spacing-m;
    left: $spacing-m;
    width: $icon-height;
    height: $icon-height;
    pointer-events: none;
  }

  &__clear {
    position: absolute;
    top: 0;
    right: 0;
    height: $places-input-height;
    width: 40px;
    svg {
      width: 12px;
      height: 12px;
      fill: $ultra-dark;
    }
  }

  &__input {
    @include font-s;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::placeholder {
      color: $dark;
    }

    font-style: normal;
    padding: 3 * $spacing-xxs;
    box-sizing: border-box;
    display: block;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(20, 30, 39, 0.19);
    padding: 0 $spacing-s 0 $spacing-xxl;
    height: $places-input-height;
    line-height: $places-input-height;
    border: 1px solid $light;
    padding: 0 $spacing-xl 0 $spacing-xxl;

    &--flat {
      box-shadow: none;
    }

    @include from-laptop {
      @include font-m;
      padding: 0 $places-input-height;
    }
  }

  &__suggestions {
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: white;

    position: absolute;
    top: $places-input-height + $spacing-xxs;
    z-index: 20;
    width: calc(100% - 2 * var(--places-input-padding));
    box-shadow: 0 2px 6px 0 rgba(20, 30, 39, 0.19);
  }
}
