@import '../../scss/base';

.places-autocomplete-suggestion {
  @include font-s;

  border-bottom: 1px solid $light;
  padding: $spacing-s $spacing-m;
  cursor: pointer;
  color: $medium;

  &:hover {
    background-color: $ultra-light;
  }

  span {
    color: black;
  }

  span > span {
    @include font-bold;
    color: $primary;
  }
}
